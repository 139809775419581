<template>
<div>
<!-- 导航 -->
<div class="11" style="display: flex;align-items: center; justify-content: space-between;padding:5px;
    background-color: #ffffff;">
  <div>
    <van-icon size="20" name="arrow-left" />
  </div>
  <div>
    <van-button type="default" color="gray" size="small" round>我的订单</van-button>
    <van-button type="default" color="#ff6d00" size="small" round>粉丝订单</van-button>
  </div>
  <div></div>
</div>
<!-- 导航 -->
<!-- 点击tap栏 -->
<div>
  <van-tabs v-model="active">
  <van-tab title="全部">
    <div style="height: 15px;background-color: #f6f6f6;"></div>
    <div  style="padding: 10px;height:190px; border: 1px solid #f6f6f6;border-radius: 10px;background-color: #ffffff;">
      <div style="display: flex;justify-content: space-between;">
        <div>曼斯菲儿旗舰店</div>
        <div style="color: #ff6d00;font-weight: bold;">已结算</div>
      </div>
      <div style="display: flex;justify-content: space-between;padding: 15px 0;">
        <div>
          <van-image width="6rem" height="7rem" fit="cover" src="https://img01.yzcdn.cn/vant/cat.jpeg" />
        </div>
        <div style="display: flex; flex-direction:column;justify-content: space-between;">
          <div>
          <p>厨房置物架收纳盒用品</p>
          <p>厨房置物架收纳盒用品</p>
          <p>厨房</p>
          </div>
          <div>规格：白色复杂</div>
        </div>
        <div style="display: flex; flex-direction:column;justify-content: space-between;align-items: flex-end;">
        <div style="color: red;font-weight:bold;">￥48.28</div>
        <div>*1</div>
        </div>
      </div>
       <div style="display:flex;justify-content: space-between;">
         <div></div>
         <div>共计1件商品</div>
         <div>
           <span>合计：</span>
           <span style="color: red;font-weight:bold;">￥48.28</span>
         </div>
         <div>
           <span>返佣：</span>
           <span style="color: red;font-weight:bold;">￥8.28</span>
         </div>
       </div>
    </div>
  </van-tab>
  <van-tab title="已结算">内容 2</van-tab>
  <van-tab title="未结算">内容 3</van-tab>
  <van-tab title="已失效">内容 4</van-tab>
</van-tabs>
</div>
<!-- 点击tap栏 -->
</div>
</template>

<script>
export default {
  name:"fansorder",
}
</script>
<style>
.van-tab--active{
  font-size: 18px;
  color: #ff6d00;
}
</style>
